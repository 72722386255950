<div class="animated fadeIn">
  <a href="#/customization" class="previous">&laquo; Back</a>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <strong>Edit User</strong>
        </div>
        <form
          autocomplete="off"
          [formGroup]="editUserForm"
          (ngSubmit)="editUser()"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="fullName">Full Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="fullName"
                    placeholder="Enter full name"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="otherName">Other Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="otherName"
                    placeholder="Enter other name"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="gender">Gender</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="gender"
                    placeholder="Enter gender"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="dob">Date of Birth</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="dob"
                    placeholder="Enter date of birth"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="profilePic">Profile Picture</label>
                  <input
                    type="file"
                    class="form-control"
                    (change)="onFileChange($event, 'profilePic')"
                    accept="image/*"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="placeOfBirth">Place of Birth</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="placeOfBirth"
                    placeholder="Enter place of birth"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="citizenship">Citizenship</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="citizenship"
                    placeholder="Enter citizenship"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="currentCountry">Current Country</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="currentCountry"
                    placeholder="Enter current country"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="countryApply">Country Applying For</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="countryApply"
                    placeholder="Enter country applying for"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="maritalStatus">Marital Status</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="maritalStatus"
                    placeholder="Enter marital status"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="spouseName">Spouse's Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="spouseName"
                    placeholder="Enter spouse's name"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="nativeLanguage">Native Language</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="nativeLanguage"
                    placeholder="Enter native language"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="passportNumber">Passport Number</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="passportNumber"
                    placeholder="Enter passport number"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="passportCountry">Country of Issue</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="passportCountry"
                    placeholder="Enter passport country of issue"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="passportIssue">Passport Issue Date</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="passportIssue"
                    placeholder="Enter passport issue date"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="passportExpiry">Passport Expiry Date</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="passportExpiry"
                    placeholder="Enter passport expiry date"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="nationalIdentity"
                    >National Identity Document</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="nationalIdentity"
                    placeholder="Enter national identity document"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="usPrCard">US Permanent Resident Card</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="usPrCard"
                    placeholder="Do you have a US PR Card?"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="visitFrom">Visit From</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="visitFrom"
                    placeholder="Enter visit start date"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="visitTo">Visit To</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="visitTo"
                    placeholder="Enter visit end date"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="consent">Consent</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="consent"
                    placeholder="Enter consent details"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    formControlName="email"
                    placeholder="Enter email"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="phone">Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="phone"
                    placeholder="Enter phone number"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="altPhone">Alternate Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="altPhone"
                    placeholder="Enter alternate phone number"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="address">Mailing Address</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="address"
                    placeholder="Enter address"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="fax">Fax Number</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="fax"
                    placeholder="Enter fax number"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="purposeVisit">Purpose of Visit</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="purposeVisit"
                    placeholder="Enter purpose of visit"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="companyName">Company Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="companyName"
                    placeholder="Enter company name"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="workProfile">Work Profile</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="workProfile"
                    placeholder="Enter work profile"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="salary">Salary</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="salary"
                    placeholder="Enter salary"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="finance">Finance</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="finance"
                    placeholder="Enter finance details"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="fundsAvailable">Funds Available</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="fundsAvailable"
                    placeholder="Enter available funds"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="education">Education</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="education"
                    placeholder="Enter education details"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="tuberculosis">Tuberculosis Status</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="tuberculosis"
                    placeholder="Enter tuberculosis status"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="medicalDisorder">Medical Disorder</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="medicalDisorder"
                    placeholder="Enter medical disorder details"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="arrested">Arrested Status</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="arrested"
                    placeholder="Enter arrested status"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="political">Political Involvement</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="political"
                    placeholder="Enter political involvement"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="fileStatus">File Status</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="fileStatus"
                    placeholder="Enter File Status"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="images">Upload Images</label>
                  <input
                    type="file"
                    class="form-control"
                    (change)="onFileChange($event, 'images')"
                    multiple
                    accept="image/*"
                  />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label for="official">Official Documents</label>
                  <input
                    type="file"
                    class="form-control"
                    (change)="onFileChange($event, 'official')"
                    multiple
                    accept="*"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="fileSubmitDate">File Submission Date</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="fileSubmitDate"
                    placeholder="Enter date of submission"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="official">Interview Call</label>
                  <input
                    type="file"
                    class="form-control"
                    (change)="onFileChange($event, 'interview')"
                    accept="*"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-lg btn-primary">
              <i class="fa fa-dot-circle-o"></i> Submit
            </button>
            <button type="reset" class="btn btn-lg btn-danger button-margin">
              <i class="fa fa-ban"></i> Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
