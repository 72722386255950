import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    name: "Getting Started",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "Users",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "Tab Users",
    url: "/tab-users",
    icon: "icon-user",
  },
  {
    name: "Europen Acting Rules",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "FAQs",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "Location Common Service Center",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "Annexures",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "Track Status",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "mEuropen Track",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "Europen Council",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "Terms and conditions",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "Contact Us",
    url: "/users",
    icon: "icon-user",
  },
  {
    name: "Check Availabilty",
    url: "/users",
    icon: "icon-user",
  },
];
